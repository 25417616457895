export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "1 star": "1 star",
        "2 stars": "2 stars",
        "3 stars": "3 stars",
        "4 stars": "4 stars",
        "5 stars": "5 stars",
        "404 - Page not found": "404 - Page not found",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "About us": "About us",
        "Active": "Active",
        "Addition": "Addition",
        "Add page block": "Add page block",
        "Age property": "Age property",
        "All blogs": "All blogs",
        "Alle blogs": "Alle blogs",
        "Alle projects": "Alle projects",
        "Alle recensies": "Alle recensies",
        "All projects": "All projects",
        "All reviews": "All reviews",
        "All rights reserved.": "All rights reserved.",
        "Amount blogs": "Amount blogs",
        "Amount projects": "Amount projects",
        "Amount reviews": "Amount reviews",
        "Back to home": "Back to home",
        "Banner": "Banner",
        "Banner / Page Header": "Banner / Page Header",
        "Bedankt voor uw definitieve offerte aanvraag, ik zal deze in behandeling nemen en zo snel mogelijk bij u terug komen.": "Bedankt voor uw definitieve offerte aanvraag, ik zal deze in behandeling nemen en zo snel mogelijk bij u terug komen.",
        "Blogs": "Blogs",
        "Blog Settings": "Blog Settings",
        "Browse": "Browse",
        "Buoy edge/roof strip(s) in linear meters": "Buoy edge/roof strip(s) in linear meters",
        "Button label": "Button label",
        "Ceiling(s) in m2": "Ceiling(s) in m2",
        "City": "City",
        "City *": "City *",
        "Comment(s)": "Comment(s)",
        "Company name": "Company name",
        "Concept": "Concept",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in only visible to logged in users, draft is not visible to anyone",
        "Contact": "Contact",
        "Contactform": "Contactform",
        "Content": "Content",
        "Content image": "Content image",
        "Cta": "Cta",
        "Definitive": "Definitive",
        "Description": "Description",
        "description": "description",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Door(s)": "Door(s)",
        "Door frame(s)": "Door frame(s)",
        "Drag & Drop your files or": "Drag & Drop your files or",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "End": "End",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "External URL": "External URL",
        "First Name": "First Name",
        "First name": "First name",
        "Flips text and image on desktop view": "Flips text and image on desktop view",
        "Flip text and image": "Flip text and image",
        "Forbidden": "Forbidden",
        "Form quote": "Form quote",
        "Gallery": "Gallery",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Go to page {page}",
        "Hello!": "Hello!",
        "Hits": "Hits",
        "House number *": "House number *",
        "How old is your property? *": "How old is your property? *",
        "Huisnummer": "Huisnummer",
        "I agree with the": "I agree with the",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "Image": "Image",
        "Index Page": "Index Page",
        "Indicatie Offerte.pdf": "Indicatie Offerte.pdf",
        "indoor_new_wall_plaster": "indoor_new_wall_plaster",
        "indoor_paint_new_wall_plaster": "indoor_paint_new_wall_plaster",
        "indoor_paint_skirting_boards": "indoor_paint_skirting_boards",
        "indoor_paint_wall_other_color": "indoor_paint_wall_other_color",
        "indoor_paint_wall_similar_color": "indoor_paint_wall_similar_color",
        "Inside": "Inside",
        "Introduction": "Introduction",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "I will contact you within 24 hours.": "I will contact you within 24 hours.",
        "Last Name": "Last Name",
        "Last name": "Last name",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Leave text empty to use the short variant as page header": "Leave text empty to use the short variant as page header",
        "Login": "Login",
        "Logout": "Logout",
        "Made by:": "Made by:",
        "Make title smaller": "Make title smaller",
        "Message *": "Message *",
        "Name": "name",
        "name": "name",
        "Name *": "Name *",
        "New: Building younger than 2 years old, Old: Building older than 2 years": "New: Building younger than 2 years old, Old: Building older than 2 years",
        "New construction (under < 2 years old)": "New construction (under < 2 years old)",
        "New URL": "New URL",
        "Next": "Next",
        "Not Found": "Not Found",
        "of": "of",
        "Old URL": "Old URL",
        "Openings hours": "Openings hours",
        "Outer facade in m2": "Outer facade in m2",
        "Outside": "Outside",
        "Over 2 years old": "Over 2 years old",
        "Page (within website)": "Page (within website)",
        "Page blocks": "Page blocks",
        "Page Expired": "Page Expired",
        "Pages": "Pages",
        "Pagination Navigation": "Pagination Navigation",
        "Painted": "Painted",
        "Parent category": "Parent category",
        "Payment Required": "Payment Required",
        "Permanent": "Permanent",
        "Personal data": "Personal data",
        "Phone": "Phone",
        "Phone *": "Phone *",
        "Phone number": "Phone number",
        "Place": "Place",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Postal code": "Postal code",
        "Postal code *": "Postal code *",
        "Previous": "Previous",
        "Privacy declaration": "Privacy declaration",
        "Project content": "Project content",
        "Projects": "Projects",
        "Quotes": "Quotes",
        "Regards": "Regards",
        "Register": "Register",
        "Renovlies": "Renovlies",
        "Request Quote": "Request Quote",
        "Reset Password": "Reset Password",
        "Reset Password Notification": "Reset Password Notification",
        "results": "results",
        "Review": "Review",
        "Reviews": "Reviews",
        "Roof dormer(s)": "Roof dormer(s)",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created",
        "Send": "Send",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Showing": "Showing",
        "Show the 3 latest blogs": "Show the 3 latest blogs",
        "Show the 3 latest projects": "Show the 3 latest projects",
        "Show the 3 latest reviews": "Show the 3 latest reviews",
        "Sliding door(s)": "Sliding door(s)",
        "Slug": "Slug",
        "Specialties": "Specialties",
        "Stairs": "Stairs",
        "Stairs)": "Stairs)",
        "Stars": "Stars",
        "Start": "Start",
        "State here, for example, wood rot, water damage, fire damage, etc.": "State here, for example, wood rot, water damage, fire damage, etc.",
        "Straatnaam": "Straatnaam",
        "Street name": "Street name",
        "Street name *": "Street name *",
        "Stucco": "Stucco",
        "Subject *": "Subject *",
        "Submit": "Submit",
        "Terms and Conditions": "Terms and Conditions",
        "terms and conditions": "terms and conditions",
        "Text": "Text",
        "Thank you!": "Thank you!",
        "Thank you for filling in!": "Thank you for filling in!",
        "Thank you for your definitive quote request": "Thank you for your definitive quote request",
        "Thank you for your message!": "Thank you for your message!",
        "Thank you for your quote request!": "Thank you for your quote request!",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The address you entered is not valid. Please try again.": "The address you entered is not valid. Please try again.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "Tilt/turn window": "Tilt/turn window",
        "Title": "Title",
        "title": "title",
        "to": "to",
        "Toggle navigation": "Toggle navigation",
        "Too Many Requests": "Too Many Requests",
        "Type": "Type",
        "Unauthorized": "Unauthorized",
        "url": "url",
        "Variants": "Variants",
        "Verify Email Address": "Verify Email Address",
        "Wall(s) in m2": "Wall(s) in m2",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We have received your message. One of our employees will contact you as soon as possible.",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "When status definitive is set, the customer has clicked the request definitive quote request button in the mail": "When status definitive is set, the customer has clicked the request definitive quote request button in the mail",
        "Whoops!": "Whoops!",
        "Window Frame(s)": "Window Frame(s)",
        "Window frame(s)": "Window frame(s)",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "Your account for {siteName}": "Your account for {siteName}",
        "You will receive the indicative quote by email within minutes.": "You will receive the indicative quote by email within minutes.",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "ascii": "The {attribute} must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "decimal": "The {attribute} must have {decimal} decimal places.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} may not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field is required.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "string": "The {attribute} must be greater than or equal {value} characters."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal {value}.",
                "string": "The {attribute} must be less than or equal {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} may not have more than {max} items.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "numeric": "The {attribute} may not be greater than {max}.",
                "string": "The {attribute} may not be greater than {max} characters."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} must contain at least one letter.",
                "mixed": "The {attribute} must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} must contain at least one number.",
                "symbols": "The {attribute} must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "ulid": "The {attribute} must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "age": "age",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "current_password": "current password",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "price": "price",
                "province": "province",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "second": "second",
                "sex": "sex",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "username": "username",
                "year": "year"
            }
        },
        "routes": {
            "blog": "blog",
            "about-us": "about-us",
            "general-terms": "general-terms",
            "privacy-policy": "privacy-policy",
            "cookie-policy": "cookie-policy",
            "contact": "contact",
            "products": "products",
            "projects": "projects",
            "quote": "quote"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "http-statuses": {
            "0": "Unknown Error",
            "100": "Continue",
            "101": "Switching Protocols",
            "102": "Processing",
            "200": "OK",
            "201": "Created",
            "202": "Accepted",
            "203": "Non-Authoritative Information",
            "204": "No Content",
            "205": "Reset Content",
            "206": "Partial Content",
            "207": "Multi-Status",
            "208": "Already Reported",
            "226": "IM Used",
            "300": "Multiple Choices",
            "301": "Moved Permanently",
            "302": "Found",
            "303": "See Other",
            "304": "Not Modified",
            "305": "Use Proxy",
            "307": "Temporary Redirect",
            "308": "Permanent Redirect",
            "400": "Bad Request",
            "401": "Unauthorized",
            "402": "Payment Required",
            "403": "Forbidden",
            "404": "Not Found",
            "405": "Method Not Allowed",
            "406": "Not Acceptable",
            "407": "Proxy Authentication Required",
            "408": "Request Timeout",
            "409": "Conflict",
            "410": "Gone",
            "411": "Length Required",
            "412": "Precondition Failed",
            "413": "Payload Too Large",
            "414": "URI Too Long",
            "415": "Unsupported Media Type",
            "416": "Range Not Satisfiable",
            "417": "Expectation Failed",
            "418": "I'm a teapot",
            "419": "Session Has Expired",
            "421": "Misdirected Request",
            "422": "Unprocessable Entity",
            "423": "Locked",
            "424": "Failed Dependency",
            "425": "Too Early",
            "426": "Upgrade Required",
            "428": "Precondition Required",
            "429": "Too Many Requests",
            "431": "Request Header Fields Too Large",
            "444": "Connection Closed Without Response",
            "449": "Retry With",
            "451": "Unavailable For Legal Reasons",
            "499": "Client Closed Request",
            "500": "Internal Server Error",
            "501": "Not Implemented",
            "502": "Bad Gateway",
            "503": "Maintenance Mode",
            "504": "Gateway Timeout",
            "505": "HTTP Version Not Supported",
            "506": "Variant Also Negotiates",
            "507": "Insufficient Storage",
            "508": "Loop Detected",
            "509": "Bandwidth Limit Exceeded",
            "510": "Not Extended",
            "511": "Network Authentication Required",
            "520": "Unknown Error",
            "521": "Web Server is Down",
            "522": "Connection Timed Out",
            "523": "Origin Is Unreachable",
            "524": "A Timeout Occurred",
            "525": "SSL Handshake Failed",
            "526": "Invalid SSL Certificate",
            "527": "Railgun Error",
            "598": "Network Read Timeout Error",
            "599": "Network Connect Timeout Error",
            "unknownError": "Unknown Error"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        }
    },
    "nl": {
        "(and {count} more error)": "(en {count} andere foutmelding)",
        "(and {count} more errors)": "(en {count} andere foutmeldingen)",
        "1 star": "1 ster",
        "2 stars": "2 sterren",
        "3 stars": "3 sterren",
        "4 stars": "4 sterren",
        "5 stars": "5 sterren",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "About us": "Over ons",
        "Active": "Actief",
        "Addition": "Toevoeging",
        "Add page block": "Pagina blok toevoegen",
        "Age property": "Leeftijd eigenschap",
        "All blogs": "Alle blogs",
        "Alle blogs": "Alle blogs",
        "Alle projects": "Alle projecten",
        "Alle recensies": "Alle recensies",
        "All projects": "Alle projecten",
        "All reviews": "Alle beoordelingen",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "Amount blogs": "Aantal blogs",
        "Amount projects": "Aantal projecten",
        "Amount reviews": "Aantal beoordelingen",
        "Back to home": "Terug naar home",
        "Banner": "Banner",
        "Banner / Page Header": "Banner / Pagina Header",
        "Bedankt voor uw definitieve offerte aanvraag, ik zal deze in behandeling nemen en zo snel mogelijk bij u terug komen.": "Bedankt voor uw definitieve offerte aanvraag, ik zal deze in behandeling nemen en zo snel mogelijk bij u terug komen.",
        "Blogs": "Blogs",
        "Blog Settings": "Blog Instellingen",
        "Browse": "Bestand selecteren",
        "Buoy edge/roof strip(s) in linear meters": "Boeideel/daklijst(en) in strekkende meter",
        "Button label": "Knoplabel",
        "Ceiling(s) in m2": "Plafond(s) in m2",
        "City": "Stad",
        "City *": "Stad *",
        "Comment(s)": "Opmerking(en)",
        "Company name": "Bedrijfsnaam",
        "Concept": "Concept",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in alleen zichtbaar voor ingelogde gebruikers, draft is voor niemand zichtbaar",
        "Contact": "Contact",
        "Contact form": "Contactformulier",
        "Contactform": "Contactformulier",
        "Content": "Inhoud",
        "Content image": "Inhoud afbeelding",
        "Create a password": "Maak een wachtwoord aan",
        "Cta": "Cta",
        "Definitive": "Definitieve",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "Door(s)": "Deur(en)",
        "Door frame(s)": "Deurkozijn(en)",
        "Drag & Drop your files or": "Sleep je bestanden of",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "End": "Einde",
        "Excerpt": "Uittreksel",
        "excerpt": "uittreksel",
        "External URL": "Externe URL",
        "Facebook URL": "Facebook URL",
        "First Name": "Voornaam",
        "First name": "Voornaam",
        "Flips text and image on desktop view": "Spiegelt tekst en afbeelding om op bureaubladweergave",
        "Flip text and image": "Tekst en afbeelding omdraaien",
        "Forbidden": "Geen toegang",
        "Form quote": "Formulier offerte",
        "Gallery": "Galerie",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Ga naar pagina {page}",
        "Hello!": "Hallo!",
        "Hits": "Hits",
        "House number *": "Huisnummer *",
        "How old is your property? *": "Hoe oud is uw woning? *",
        "Huisnummer": "Huisnummer",
        "I agree with the": "Ik ben het eens met de",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "Image": "Afbeelding",
        "Index Page": "Index pagina",
        "Indicatie Offerte.pdf": "Indicatie Offerte.pdf",
        "indoor_ceilings_painted_cost": "Binnen plafond geschilderd kosten (per m2)",
        "indoor_ceilings_stucco_cost": "Binnen plafond stucwerk kosten (per m2)",
        "indoor_doors_cost": "Binnen deuren kosten",
        "indoor_door_frames_cost": "Binnen deurkozijnen kosten",
        "indoor_new_wall_plaster": "Nieuw stucwerk aanvragen in m2",
        "indoor_new_wall_plasters_cost": "Nieuw stucwerk aanvragen kosten (per m2)",
        "indoor_new_wall_plaster_cost": "Nieuw stucwerk kosten (per m2)",
        "indoor_paint_new_wall_plaster": "Nieuw stucwerk of renovlies sausen in m2",
        "indoor_paint_new_wall_plaster_cost": "Nieuw stucwerk of renovlies sausen kosten (per m2)",
        "indoor_paint_skirting_boards": "Plinten in strekkende meter",
        "indoor_paint_skirting_boards_cost": "Plinten in strekkende meter kosten",
        "indoor_paint_wall_other_color": "Bestaande wanden en plafonds andere kleur sausen in m2",
        "indoor_paint_wall_other_color_cost": "Bestaande wanden en plafonds andere kleur sausen (per m2)",
        "indoor_paint_wall_similar_color": "Bestaande gesausde wanden en plafonds zelfde kleur sausen in m2",
        "indoor_paint_wall_similar_color_cost": "Bestaande gesausde wanden en plafonds zelfde kleur sausen (per m2)",
        "indoor_sliding_doors_cost": "Binnen schuifdeuren kosten",
        "indoor_stairs": "Binnen trap(pen) inclusief trapboom/hekwerk",
        "indoor_stairs_cost": "Binnen trap(pen) inclusief trapboom/hekwerk kosten",
        "indoor_tilt_turn_window_cost": "Binnen draai en/of kiepramen kosten",
        "indoor_wall_painted_cost": "Binnenmuur geschilderd kosten (per m2)",
        "indoor_wall_renovlies_cost": "Nieuw renovlies aanvragen kosten (per m2)",
        "indoor_wall_renovlies": "Nieuw renovlies aanvragen in m2",
        "indoor_wall_stucco_cost": "Binnenmuur stucwerk kosten (per m2)",
        "indoor_window_frames_cost": "Binnen raamkozijn(en) met vast glas kosten",
        "indoor_window_frames": "Binnen raamkozijn(en) met vast glas",
        "indoor_tilt_turn_window": "Binnen draai en/of kiepramen",
        "Inside": "Binnen",
        "Instagram URL": "Instagram URL",
        "Introduction": "Inleiding",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribuut} tussen {min} en {max} tekens lang te houden.",
        "I will contact you within 24 hours.": "Ik neem binnen 24 uur contact met je op.",
        "Kind regards,": "Met vriendelijke groet,",
        "KVK number": "KVK-nummer",
        "Last Name": "Achternaam",
        "Last name": "Achternaam",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "Leave text empty to use the short variant as page header": "Laat tekst leeg om de korte variant als paginakop te gebruiken",
        "LinkedIn URL": "LinkedIn URL",
        "Login": "Inloggen",
        "Wall(s) and ceiling(s) in m2": "Wand(en) en plafond(s) in m2",
        "Logout": "Uitloggen",
        "Made by:": "Gerealiseerd door:",
        "Make title smaller": "Titel kleiner maken",
        "Message *": "Bericht *",
        "Name": "naam",
        "name": "naam",
        "Name *": "Naam *",
        "New: Building younger than 2 years old, Old: Building older than 2 years": "Nieuw: Gebouw jonger dan 2 jaar, Oud: Gebouw ouder dan 2 jaar",
        "New construction (under < 2 years old)": "Nieuwbouw (jonger dan < 2 jaar)",
        "New stucco painting": "Nieuw stucwerk schilderen",
        "New URL": "Nieuwe URL",
        "Next": "Volgende",
        "Nieuw stucwerk muur": "Nieuw stucwerk muur",
        "Nieuw stucwerk plafond": "Nieuw stucwerk plafond",
        "Not Found": "Niet gevonden",
        "of": "van",
        "Old URL": "Oude URL",
        "Openings hours": "Openingstijden",
        "outdoor_buoy_edge_roof_cost": "Buiten boeideel daklijst kosten (per m)",
        "outdoor_doors_cost": "Buiten deuren kosten",
        "outdoor_door_frames_cost": "Buiten deurkozijnen kosten",
        "outdoor_facade_cost": "Buiten gevel kosten (per m2)",
        "outdoor_roof_dormers_cost": "Buiten dakkapel kosten",
        "outdoor_garage_rolling_door": "Buiten garage roldeur",
        "outdoor_garage_rolling_door_cost": "Buiten garage roldeur kosten",
        "outdoor_sliding_doors_cost": "Buiten schuifdeuren kosten",
        "outdoor_rath_board": "Buiten rabatdelen in m2",
        "outdoor_rath_board_cost": "Buiten rabatdelen kosten (per m2)",
        "outdoor_window_frames": "Buiten raamkozijn(en) met vast glas",
        "outdoor_tilt_turn_window": "Buiten draai en/of kiepramen",
        "outdoor_tilt_turn_window_cost": "Buiten draai en/of kiepramen raam kosten",
        "outdoor_window_frames_cost": "Buiten Raamkozijn (en) met vast glas kosten",
        "Outer facade in m2": "Buitengevel in m2",
        "Outside": "Buiten",
        "Over 2 years old": "Ouder dan 2 jaar",
        "Page": "Pagina",
        "Page (within website)": "Pagina (binnen website)",
        "Page blocks": "Pagina blokken",
        "Page Expired": "Pagina niet meer geldig",
        "Pages": "Pagina's",
        "Pagination Navigation": "Paginanavigatie",
        "Painted": "Geschilderd",
        "Parent category": "Hoofdcategorie",
        "Payment Required": "Betaling Vereist",
        "Permanent": "Permanent",
        "Personal data": "Persoonlijke gegevens",
        "Phone": "Telefoon",
        "Phone *": "Telefoon *",
        "Phone number": "Telefoonnummer",
        "Place": "Plaats",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Postal code": "Postcode",
        "Postal code *": "Postcode *",
        "Previous": "Vorige",
        "Privacy declaration": "Privacyverklaring",
        "Project content": "Inhoud van het project",
        "Projects": "Projecten",
        "Quotes": "Offertes",
        "Regards": "Met vriendelijke groet",
        "Register": "Registreren",
        "Renovlies": "Renovlies",
        "Request final quote": "Definitieve offerte aanvragen",
        "Requesting new plastering": "Nieuw stucwerk aanvragen",
        "Request Quote": "Offerte aanvragen",
        "Reset Password": "Wachtwoord herstellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "results": "resultaten",
        "Review": "Review",
        "Reviews": "Reviews",
        "Roof dormer(s)": "Dakkapel(len)",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Selecteer een template om de bijbehorende blokken toe te voegen. Let op: dit kan niet worden gewijzigd nadat de pagina is aangemaakt.",
        "Send": "Verzenden",
        "SEO Settings for {lang}": "SEO instellingen voor {lang}",
        "SEO title": "SEO titel",
        "Server Error": "Server fout",
        "Service Unavailable": "Website onbeschikbaar",
        "Showing": "Toont",
        "Show the 3 latest blogs": "Toon de 3 laatste blogs",
        "Show the 3 latest projects": "Toon de 3 nieuwste projecten",
        "Show the 3 latest reviews": "Toon de 3 laatste reviews",
        "Sliding door(s)": "Schuifdeur(en)",
        "Slug": "Permalink",
        "Specialties": "Specialiteiten",
        "Stairs": "Trap(pen)",
        "Stairs)": "Trappen)",
        "Stars": "Sterren",
        "Start": "Start",
        "State here, for example, wood rot, water damage, fire damage, etc.": "Vermeld hier bijvoorbeeld houtrot, waterschade, brandschade, enz.",
        "Straatnaam": "Straatnaam",
        "Street name": "Straatnaam",
        "Street name *": "Straatnaam *",
        "Stucco": "Nieuw stucwerk",
        "Subject *": "Onderwerp *",
        "Submit": "Verzenden",
        "Terms and Conditions": "Algemene Voorwaarden",
        "terms and conditions": "algemene voorwaarden",
        "Text": "Tekst",
        "Thank you!": "Bedankt!",
        "Thank you for filling in!": "Bedankt voor het invullen!",
        "Thank you for your definitive quote request": "Bedankt voor uw definitieve offerteaanvraag",
        "Thank you for your message!": "Bedankt voor je bericht!",
        "Thank you for your quote request!": "Bedankt voor uw offerteaanvraag!",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The address you entered is not valid. Please try again.": "Het ingevoerde adres is ongeldig. Probeer het opnieuw.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "This link expires in {number} hours": "Deze link verloopt over {number} uur",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "Tilt/turn window": "Draai en/of kiepramen",
        "Title": "Titel",
        "title": "titel",
        "to": "tot",
        "Toggle navigation": "Schakel navigatie",
        "Too Many Requests": "Te veel serververzoeken",
        "Type": "Type",
        "Unauthorized": "Onbevoegd",
        "url": "url",
        "Variants": "Varianten",
        "VAT number": "BTW-nummer",
        "Verify Email Address": "Verifieer e-mailadres",
        "Wall(s) in m2": "Wand(en) in m2",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We hebben uw bericht ontvangen. Een van onze medewerkers neemt zo snel mogelijk contact met u op.",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "Indien ingeschakeld, wordt een e-mail naar de gebruiker gestuurd om hem te laten weten dat zijn account is aangemaakt.",
        "When status definitive is set, the customer has clicked the request definitive quote request button in the mail": "Als de status definitief is ingesteld, heeft de klant op de knop voor het aanvragen van een definitieve offerte in de e-mail geklikt",
        "Whoops!": "Oeps!",
        "Window Frame(s)": "Kozijn(en)",
        "Window frame(s)": "Kozijn(en)",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "Your account for {siteName}": "Je account voor {siteName}",
        "You will receive the indicative quote by email within minutes.": "Je ontvangt de indicatieve offerte binnen enkele minuten per e-mail.",
        "You will receive this email because your account is ready for {siteName}": "Je ontvangt deze e-mail omdat je account klaar is voor {siteName}",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "ascii": "De {attribute} mag alleen alfanumerieke tekens en symbolen van één byte bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "decimal": "De {attribute} moet {decimal} decimalen hebben.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "{Attribute} mag niet eindigen met één van de volgende waarden: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "{Attribute} mag alleen kleine letters bevatten.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "{Attribute} mag niet uit meer dan {max} cijfers bestaan.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "{Attribute} moet minimaal uit {min} cijfers bestaan.",
            "missing": "Het veld {attribute} moet ontbreken.",
            "missing_if": "Het veld {attribute} moet ontbreken als {other} {value} is.",
            "missing_unless": "Het veld {attribute} moet ontbreken, tenzij {other} {value} is.",
            "missing_with": "Het veld {attribute} moet ontbreken wanneer {values} aanwezig is.",
            "missing_with_all": "Het veld {attribute} moet ontbreken wanneer er {values} aanwezig zijn.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "{Attribute} is verplicht indien {other} is geaccepteerd.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "ulid": "De {attribute} moet een geldige ULID zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "{Attribute} mag alleen hoofdletters bevatten.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "age": "leeftijd",
                "amount": "bedrag",
                "area": "regio",
                "available": "beschikbaar",
                "birthday": "verjaardag",
                "body": "lichaam",
                "city": "stad",
                "content": "inhoud",
                "country": "land",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "current_password": "huidig wachtwoord",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "first_name": "voornaam",
                "gender": "geslacht",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "postal_code": "postcode",
                "price": "prijs",
                "province": "provincie",
                "recaptcha_response_field": "recaptcha antwoordveld",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "second": "seconde",
                "sex": "geslacht",
                "short_text": "korte tekst",
                "size": "grootte",
                "state": "staat",
                "street": "straatnaam",
                "student": "leerling",
                "subject": "onderwerp",
                "teacher": "docent",
                "terms": "voorwaarden",
                "test_description": "test beschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "updated_at": "bijgewerkt op",
                "username": "gebruikersnaam",
                "year": "jaar"
            }
        },
        "routes": {
            "frequently-asked-questions": "veelgestelde-vragen",
            "blog": "blog",
            "about-us": "over-ons",
            "general-terms": "algemene-voorwaarden",
            "products": "producten",
            "privacy-policy": "privacy-beleid",
            "cookie-policy": "cookie-beleid",
            "contact": "contact",
            "search": "zoeken",
            "projects": "projecten",
            "quote": "offerte"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "http-statuses": {
            "0": "Onbekende foutmelding",
            "100": "Doorgaan",
            "101": "Protocolwissel",
            "102": "Verwerken",
            "200": "Oké",
            "201": "Aangemaakt",
            "202": "Aanvaard",
            "203": "Niet-gemachtigde informatie",
            "204": "Geen inhoud",
            "205": "Inhoud opnieuw instellen",
            "206": "Gedeeltelijke inhoud",
            "207": "Meerdere statussen",
            "208": "Al gemeld",
            "226": "Ik ben gebruikt",
            "300": "Meerkeuze",
            "301": "Definitief verplaatst",
            "302": "Gevonden",
            "303": "Zie andere",
            "304": "Niet gewijzigd",
            "305": "Gebruik Proxy",
            "307": "Tijdelijke omleiding",
            "308": "Definitieve omleiding",
            "400": "Foute aanvraag",
            "401": "Niet geautoriseerd",
            "402": "Betaalde toegang",
            "403": "Verboden toegang",
            "404": "Niet gevonden",
            "405": "Methode niet toegestaan",
            "406": "Niet aanvaardbaar",
            "407": "Authenticatie op de proxyserver verplicht",
            "408": "Aanvraagtijd verstreken",
            "409": "Conflict",
            "410": "Verdwenen",
            "411": "Lengte vereist",
            "412": "Niet voldaan aan de vooraf gestelde voorwaarde",
            "413": "Aanvraag te groot",
            "414": "Aanvraag-URL te lang",
            "415": "Media-type niet ondersteund",
            "416": "Bereik niet bevredigend",
            "417": "Niet voldaan aan verwachting",
            "418": "Ik ben een theepot",
            "419": "Pagina verlopen",
            "421": "Verkeerd geadresseerd verzoek",
            "422": "Aanvraag kan niet worden verwerkt",
            "423": "Afgesloten",
            "424": "Gefaalde afhankelijkheid",
            "425": "Te vroeg",
            "426": "Upgrade nodig",
            "428": "Voorwaarde nodig",
            "429": "Te veel requests",
            "431": "Headers van de aanvraag te lang",
            "444": "Verbinding gesloten zonder reactie",
            "449": "Opnieuw proberen met",
            "451": "Toegang geweigerd om juridische redenen",
            "499": "Klant Gesloten Verzoek",
            "500": "Interne serverfout",
            "501": "Niet geïmplementeerd",
            "502": "Slechte poort",
            "503": "Dienst niet beschikbaar",
            "504": "Gateway-time-out",
            "505": "HTTP-versie wordt niet ondersteund",
            "506": "Variant onderhandelt ook",
            "507": "Onvoldoende opslag",
            "508": "Loop gedetecteerd",
            "509": "Bandbreedte overschreden",
            "510": "Niet verlengd",
            "511": "Netwerkauthenticatie vereist",
            "520": "Onbekende foutmelding",
            "521": "Webserver is onbereikbaar",
            "522": "Connectie duurt te lang",
            "523": "Herkomst is onbereikbaar",
            "524": "Time-out opgetreden",
            "525": "SSL-handshake mislukt",
            "526": "Ongeldig SSL-certificaat",
            "527": "Railgun foutmelding",
            "598": "Time-outfout netwerk lezen",
            "599": "Fout bij time-out netwerkverbinding",
            "unknownError": "Onbekende foutmelding"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met het e-mailadres."
        }
    }
}
